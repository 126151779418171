/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";
import tw, { css } from "twin.macro";
import Img from "gatsby-image";

const Image = ({ image, inject }) => {
  if (!image?.asset) {
    return <></>;
  }

  const {
    asset: { fluid, description, originalFilename, title }
  } = image;

  const fluidKeys = Object.keys(fluid);
  const fluidEnriched = {};

  if (fluidKeys?.[0]) {
    fluidKeys.forEach((fluidKey) => {
      let value = fluid?.[fluidKey]?.toString();

      if (value.includes(`.jpg`)) {
        value = value.replace(/.jpg/g, `.jpg/${originalFilename}`);
      } else if (value.includes(`.png`)) {
        value = value.replace(/.png/g, `.png/${originalFilename}`);
      }

      fluidEnriched[fluidKey] = value;
    });
  }

  //

  return (
    <>
      {(fluidEnriched?.src?.includes(`.svg`) && (
        <img
          css={[
            css`
              ${inject?.css || ``}
            `,
            inject?.tw || tw`w-full relative block`
          ]}
          src={fluidEnriched.src}
          alt={description || originalFilename}
          title={title || originalFilename}
        />
      )) || (
        <Img
          css={[
            css`
              ${inject?.css || ``}
            `,
            inject?.tw || tw`w-full relative block`
          ]}
          fluid={fluidEnriched}
          alt={description || originalFilename}
          placeholderStyle={{ visibility: `hidden` }}
          title={title || originalFilename}
        />
      )}
    </>
  );
};

Image.defaultProps = {
  inject: null
};
Image.propTypes = {
  image: PropTypes.shape({}).isRequired,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  })
};

export default Image;
