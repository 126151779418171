import React, { useState } from "react";
import { PropTypes } from "prop-types";
import tw, { css, theme } from "twin.macro";
import * as T from "~components/styles/Typography.jsx";

const Button = ({
  children,
  color,
  font,
  inject,
  onClick,
  text,
  transparent
}) => {
  const [hovered, setHovered] = useState(false);

  let background = theme`colors.white`;
  let border = theme`colors.black`;
  let fontColor = theme`colors.black`;
  let hoverBackgroundOverride;
  let hoverFontOverride;

  switch (color) {
    case `black`:
      if (transparent) {
        background = `transparent`;
        border = theme`colors.black`;
        fontColor = theme`colors.black`;
        hoverFontOverride = theme`colors.white`;
      } else {
        background = theme`colors.black`;
        border = theme`colors.black`;
        fontColor = theme`colors.white`;
      }

      break;

    case `green`:
      if (transparent) {
        background = `transparent`;
        border = theme`colors.green`;
        fontColor = theme`colors.green`;
        hoverFontOverride = theme`colors.black`;
      } else {
        background = theme`colors.black`;
        border = theme`colors.black`;
        fontColor = theme`colors.white`;
        hoverBackgroundOverride = theme`colors.green`;
        hoverFontOverride = theme`colors.black`;
      }

      break;

    case `white`:
      if (transparent) {
        background = `transparent`;
        border = theme`colors.white`;
        fontColor = theme`colors.white`;
        hoverFontOverride = theme`colors.black`;
      } else {
        background = theme`colors.white`;
        border = theme`colors.white`;
        fontColor = theme`colors.black`;
      }

      break;

    default:
      break;
  }

  let textJSX;

  switch (font) {
    case `l`:
    case `L`:
    case `large`:
    case `LARGE`:
      textJSX = <T.Body>{text}</T.Body>;

      break;

    case `default`:
    default:
      textJSX = <T.Button>{text}</T.Button>;
      break;
  }

  //

  return (
    <button
      type="button"
      css={[
        css`
          ${inject?.css || ``}

          position: relative;
          display: block;
          padding: 0.25rem 1rem;
          overflow: hidden;
          border-radius: 9999px;

          background-color: ${background};
          border: 1px solid ${border};
          color: ${fontColor};

          @media not all and (pointer: coarse) {
            &:hover {
              background-color: ${hoverBackgroundOverride || fontColor};
              color: ${hoverFontOverride || background};
            }
          }
        `,
        inject?.tw || tw``
      ]}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {(!children && (
        <div tw="w-full h-full relative flex items-center justify-center text-center">
          {textJSX}
        </div>
      )) ||
        children}
    </button>
  );
};

Button.defaultProps = {
  children: null,
  color: `white`,
  font: `default`,
  inject: null,
  onClick: () => {},
  text: `Submit`,
  transparent: false
};

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  font: PropTypes.string,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  }),
  onClick: PropTypes.func,
  text: PropTypes.string,
  transparent: PropTypes.bool
};

export default Button;
