import { graphql, useStaticQuery } from "gatsby";

const useShopifyAdminProducts = () => {
  const data = useStaticQuery(graphql`
    query useShopifyAdminProducts {
      allShopifyAdminProduct {
        edges {
          node {
            products {
              alternative_id
              handle

              variants {
                alternative_id
                inventory_item_id
                title
                sku
              }
            }
          }
        }
      }
    }
  `);

  return data;
};

export default useShopifyAdminProducts;
