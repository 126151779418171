/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import tw, { css } from "twin.macro";
import { DocumentContext } from "~context/DocumentContext.jsx";

const ScreenHeight = ({ children, inject }) => {
  const { windowHeight } = useContext(DocumentContext);

  //

  const cssString = `
    height: 100vh;
    height: ${windowHeight}px;

    ${inject?.css || ``};
  `;

  return (
    <section
      css={[
        css`
          ${cssString}
        `,
        inject?.tw || tw`block`
      ]}
    >
      {children}
    </section>
  );
};

export default ScreenHeight;
