import React, { useContext, useEffect } from "react";
import { CacheContext } from "~context/CacheContext.jsx";
import useSanityShopifyProducts from "~hooks/useSanityShopifyProducts.jsx";
import useShopifyAdminProducts from "~hooks/useShopifyAdminProducts.jsx";

const Inventory = () => {
  const { refreshInventory } = useContext(CacheContext);

  const { allSanityShopifyProduct } = useSanityShopifyProducts();
  const { allShopifyAdminProduct } = useShopifyAdminProducts();

  useEffect(() => {
    refreshInventory(allSanityShopifyProduct, allShopifyAdminProduct);
  }, []);

  return <></>;
};

export default Inventory;
