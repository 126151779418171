import React, { useContext } from "react";
import tw, { css } from "twin.macro";
import { Global } from "@emotion/react";
import PropTypes from "prop-types";
import { AppContext } from "~context/AppContext.jsx";
import Inventory from "~components/Inventory.jsx";
import Nav from "~components/Nav.jsx";

const Layout = ({ children, inject }) => {
  // ===========================================================================
  // context / ref / state

  const { pathname } = useContext(AppContext);

  // ===========================================================================
  // render

  return (
    <>
      <Global
        styles={[
          css`
            a:focus,
            button:focus,
            input:focus,
            textarea:focus {
              outline: none;
            }

            input,
            textarea {
              appearance: none;
              border-radius: 0 !important;
            }
          `
        ]}
      />

      <Inventory />

      <Nav />

      <main
        id="layout"
        css={[
          css`
            ${inject?.css || ``}

            min-height: calc(100vh - 200px);
            padding-top: 4rem;

            @media screen and (min-width: 1025px) {
              padding-top: ${pathname === `/` ? `15vw` : `3rem`};
            }

            @media screen and (min-width: 1601px) {
              ${pathname === `/` && `padding-top: 240px;`}
            }
          `,
          inject?.tw || tw``
        ]}
      >
        {children}
      </main>
    </>
  );
};

Layout.defaultProps = {
  inject: null
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  })
};

export default Layout;
